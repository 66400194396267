import jsuites from 'jsuites'

export function allowedKeys(innerText, keyCode) {
  return (
    isNaN(String.fromCharCode(keyCode)) &&
    keyCode != 44 &&
    keyCode != 46 &&
    !(innerText == '' && keyCode == 61) &&
    !(innerText == '' && keyCode == 45) &&
    innerText[0] != '='
  )
}

export function editorKeyPressEvent(e, instance) {
  if (allowedKeys(instance.parent.input.innerText, e.which)) e.preventDefault()
}

function putValueInInnerText(input, value) {
  if (value)
    input.innerText =
      typeof value == 'string' && value[0] == '=' ? value : Number(value)
}

export function openEditor(cell, value, x, y, instance) {
  instance.parent.input.onblur = function (e) {
    instance.closeEditor(cell, true)
  }
  instance.parent.input.onkeypress = (e) => editorKeyPressEvent(e, instance)
  putValueInInnerText(instance.parent.input, value)
  jsuites.focus(instance.parent.input)
}

export function closeEditor(cell, save, x, y, instance) {
  instance.parent.input.onkeypress = (e) => {}
  if (instance.parent.input.innerText[0] == '=')
    return instance.parent.input.innerText
  if (save) return Number(instance.parent.input.innerText.replace(',', '.'))
  return ''
}

export function returnVal(options, val) {
  return val
}
