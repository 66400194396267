<template>
  <div>
    <div
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-[10000] outline-none focus:outline-none justify-center items-center flex sm:mx-6"
    >
      <div
        class="relative my-6 mx-auto w-120"
        v-bind:class="{ 'w-120': !isLarge, 'w-208': isLarge }"
      >
        <!--content-->
        <div
          class="rounded-lg shadow-lg relative flex flex-col w-full bg-promy-gray-100 outline-none focus:outline-none"
        >
          <!--header-->
          <div class="w-full bg-white py-4 px-6 rounded-t-lg">
            <slot name="header">
              <h1 class="text-promy-purple-400 font-black text-xl font-main">
                Confirmation
              </h1>
            </slot>
          </div>

          <!--body-->
          <div class="relative flex-auto py-4 px-6">
            <slot name="body"></slot>
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-center w-full mb-4 rounded-b px-6"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
