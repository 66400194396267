import transport from '@/plugins/axios'

export default {
  namespaced: true,

  state: {
    topics: [],
    templates: [],
    template_version: 0,
    sync_calculations: false,
    selectedTemplate: {},
    loading: false,
    types: [],
    selectedType: {},
    pagination: {},
    percentages: [],
    syncPvtIndex: 0,
    totalPercentages: 0,
    lastPage: 1,
    currentPage: 0,
  },

  getters: {
    getTemplateVersion: (state) => state.template_version,
    getTopics: (state) => state.topics,
    getTypes: (state) => state.types,
    getTemplates: (state) => state.templates,
    getSelectedTemplate: (state) => state.selectedTemplate,
    getSyncCalculation: (state) => state.sync_calculations,
    getPercentages: (state) => state.percentages,
    getTotalPercentages: (state) => state.totalPercentages,
    getSyncPvtIndex: (state) => state.syncPvtIndex,
    getCurrentPage: (state) => state.currentPage,
    getLastPage: (state) => state.lastPage,
    isLoading: (state) => state.loading,
  },

  mutations: {
    DISPATCH_SYNC_PVT(state) {
      state.syncPvtIndex = state.syncPvtIndex + 1
    },
    SET_TOPICS(state, payload) {
      state.topics = payload
    },
    SET_TEMPLATES(state, payload) {
      state.templates = payload
    },
    SET_TEMPLATE(state, payload) {
      const index = state.templates.findIndex((p) => p.id == payload.id)
      state.templates = state.templates.map((p) => {
        if (p.type.id == payload.type.id) {
          return {
            ...p,
            default: false,
          }
        } else return p
      })
      state.templates[index] = payload
    },
    SET_TEMPLATE_VERSION(state, payload) {
      state.template_version = payload
    },
    SYNC_CALCULATIONS(state, payload) {
      state.sync_calculations = !state.sync_calculations
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_PAGINATION(state, obj) {
      state.pagination = obj
    },
    SET_SELECTED_TEMPLATE(state, obj) {
      state.selectedTemplate = obj
    },
    SET_SELECTED_TYPE(state, obj) {
      state.selectedType = obj
    },
    SET_TYPES(state, obj) {
      state.types = obj
    },
    ADD_PERCENTAGE(state, percentage) {
      const index = state.percentages.findIndex(
        (p) => p.sheet == percentage.sheet,
      )
      if (index == -1) {
        state.percentages.push(percentage)
      } else {
        state.percentages[index] = percentage
      }

      state.totalPercentages =
        state.percentages.length > 0
          ? state.percentages.reduce((total, p) => {
              return {
                value: total.value + p.value,
              }
            }).value
          : 0
    },
    REMOVE_PERCENTAGE(state, topicName) {
      const index = state.percentages.findIndex((p) => p.sheet == topicName)
      if (index !== -1) {
        state.percentages.splice(index, 1)
        state.totalPercentages =
          state.percentages.length > 0
            ? state.percentages.reduce((total, p) => {
                return {
                  value: total.value + p.value,
                }
              }).value
            : 0
      }
    },
    RESET_PERCENTAGES(state) {
      state.percentages = []
      state.totalPercentages = 0
    },
    REMOVE_TEMPLATE(state, id) {
      state.templates = state.templates.filter((template) => template.id != id)
    },
    SET_CURRENT_PAGE(state, currentPage) {
      state.currentPage = currentPage
    },
    SET_LAST_PAGE(state, lastPage) {
      state.lastPage = lastPage
    },
    RESET(state) {
      state.lastPage = 1
      state.currentPage = 0
      state.templates = []
    },
  },

  actions: {
    /* ---------------------------- Template Actions ---------------------------- */
    async getTemplates({ commit, getters }, sort) {
      commit('SET_LOADING', true)
      commit('SET_CURRENT_PAGE', getters.getCurrentPage + 1)
      let list = await transport.get(
        `admin/bilan/templates?page=${getters.getCurrentPage}${
          sort ? `&sortKey=${sort.key}&sortOrder=${sort.order}` : ''
        }`,
      )
      commit('SET_PAGINATION', list.data.meta)
      commit('SET_TEMPLATES', list.data.data)
      commit('SET_CURRENT_PAGE', list.data.meta.current_page)
      commit('SET_LAST_PAGE', list.data.meta.last_page)
      commit('SET_LOADING', false)
    },
    async toggleDefault({ commit }, id) {
      commit('SET_LOADING', true)
      let template = await transport.get(`admin/bilan/template/default/${id}`)
      commit('SET_TEMPLATE', template.data.data)
      commit('SET_LOADING', false)
    },
    async getTemplatesForType({ commit }, payload) {
      commit('SET_LOADING', true)
      let list = await transport.get(
        `admin/bilan/template/${payload.type}?page=${payload.page}`,
      )
      commit('SET_PAGINATION', list.data.meta)
      commit('SET_TEMPLATES', list.data.data)
      commit('SET_LOADING', false)
    },
    async getTypes({ commit }) {
      let list = await transport.get(`admin/bilan/types`)
      commit('SET_TYPES', list.data.data)
    },
    async getLatestTemplateForType({ commit }, id) {
      try {
        commit('SET_TOPICS', [])
        let response = await transport.get(`admin/bilan/template/latest/${id}`)
        commit('SET_TOPICS', response.data.data.topics)
        commit('SET_TEMPLATE_VERSION', response.data.data.version)
        commit('SET_SELECTED_TEMPLATE', response.data.data)
      } catch (e) {
        console.log('Error get template : ', e.message)
      }
    },
    async getTemplateById({ commit }, id) {
      try {
        commit('SET_TOPICS', [])
        let response = await transport.get(`admin/bilan/templates/${id}`)
        commit('SET_TOPICS', response.data.data.topics)
        commit('SET_TEMPLATE_VERSION', response.data.data.version)
        commit('SET_SELECTED_TEMPLATE', response.data.data)
      } catch (e) {
        console.log('Error get template : ', e.message)
      }
    },
    async saveTemplate({ commit }, payload) {
      try {
        await transport.post('admin/bilan/templates', payload)
      } catch (e) {
        console.log('Error save template : ', e.message)
      }
    },
    async updateTemplate({ commit }, payload) {
      try {
        await transport.put(`admin/bilan/templates/${payload.id}`, payload)
      } catch (e) {
        console.log('Error update template : ', e.message)
      }
    },
    async deleteTemplate({ commit }, id) {
      try {
        let response = await transport.delete(`admin/bilan/templates/${id}`)
        if (response.status == 204) commit('REMOVE_TEMPLATE', id)
      } catch (e) {
        console.log('Error delete template : ', e.message)
      }
    },
    async orderBy({ commit, dispatch }, { key, order }) {
      commit('RESET')
      dispatch('getTemplates', { key, order })
    },
  },
}
