export default {
  data() {
    return {
      first_source_layers_parcelle: [
        'promy-parcelle',
        'promy-background-parcelle',
        'promy-layer-outline-parcelle',
      ],
      source_layers_selected_parcelles_autour: [
        'promy-parcelles_autour',
        'promy-background-parcelle-autour',
        'promy-parcelles_autour',
      ],
      source_and_layers_parcelles_voisines: [
        'promy-selected-voisines',
        'promy-background-selected-voisines',
        'promy-outline-selected-voisines',
      ],
      source_layers_selected_parcelle: [
        'promy-selected-parcelle',
        'promy-background-selected-parcelle',
        'promy-outline-selected-parcelle',
      ],
      init_source_and_layers_parcelles_voisines: [
        'promy-selected-voisines',
        'promy-background-selected-voisines',
        'promy-outline-selected-voisines',
      ],
      source_and_layers_parcelles_aggregate: [
        'promy-selected-aggregate',
        'promy-background-selected-aggregate',
        'promy-outline-selected-aggregate',
      ],
      source_layers_batiments: [
        'promy-batiments',
        'promy-background_batiments',
        'promy-layer-outline-bati',
      ],
      source_layers_all_parcelle: [
        'promy-all_parcelle',
        'promy-background-all-parcelle',
        'promy-layer-outline-all-parcelle',
      ],
      source_layers_all_zones_urba: [
        'promy-all-zones-urba',
        'promy-background-all-zones-urba',
        'promy-layer-outline-all-zones-urba',
      ],
      source_layers_selected_batiment: [
        'promy-selected-batiment',
        'promy-background-selected-batiment',
        'promy-outline-selected-batiment',
      ],
      source_and_layers_urbanisme: [
        'promy-urbanisme',
        'promy-background_urbanisme',
        'promy-layer-outline-urbanisme',
      ],
      layer_background_parcelle_autour: 'promy-background-parcelle-autour',
      source_layers_batisExteriorPieces: [
        'promy-batisExteriorPieces',
        'promy-background_batisExteriorPieces',
        'promy-layer-outline-batisExteriorPieces',
      ],
      source_and_layers_route_driving: [
        'promy-route_driving',
        'promy-background_route_driving',
        'promy-layer-outline-route_driving',
      ],
      source_and_layers_route_walking: [
        'promy-route_walking',
        'promy-background_route_walking',
        'promy-layer-outline-route_walking',
      ],
      source_and_layers_cluster: [
        'promy-cluster',
        'promy-background_cluster',
        'promy-background_cluster',
      ],
      source_and_layers_cadastre: [
        'promy-wms-cadastre-officiel',
        'promy-wms-layer-cadastre-officiel',
      ],
      source_and_layers_libelle_urbanisme_autour: [
        'promy-libelle-urbanisme-autour',
        'promy-background-libelle-urbanisme-autour',
        'promy-layer-outline-libelle-urbanisme-autour',
      ],
    }
  },
  methods: {
    getAllLayers() {
      return [
        ...this.first_source_layers_parcelle,
        ...this.source_layers_selected_parcelles_autour,
        ...this.source_and_layers_parcelles_voisines,
        ...this.source_layers_selected_parcelle,
        ...this.source_layers_all_parcelle,
        ...this.init_source_and_layers_parcelles_voisines,
        ...this.source_and_layers_parcelles_aggregate,
        ...this.source_and_layers_route_driving,
        ...this.source_and_layers_route_walking,
        ...this.source_and_layers_cluster,
        this.layer_background_parcelle_autour,
        ...this.source_and_layers_urbanisme,
        ...this.source_and_layers_cadastre,
        ...this.source_and_layers_libelle_urbanisme_autour,
      ]
    },
    getLayersParcelle() {
      return [
        ...this.first_source_layers_parcelle,
        ...this.source_layers_selected_parcelles_autour,
        ...this.source_and_layers_parcelles_voisines,
        ...this.source_layers_selected_parcelle,
        ...this.source_and_layers_parcelles_aggregate,
        ...this.init_source_and_layers_parcelles_voisines,
        this.layer_background_parcelle_autour,
      ]
    },
    getLayersUrbanisme() {
      return [
        ...this.source_layers_all_parcelle,
        ...this.source_and_layers_urbanisme,
        this.source_and_layers_cadastre[1],
      ]
    },
    getLayersLocalisation() {
      return [
        ...this.source_layers_all_parcelle,
        ...this.source_and_layers_route_driving,
        ...this.source_and_layers_route_walking,
        ...this.source_and_layers_cluster,
      ]
    },
    getLayersBatiments() {
      return [
        ...this.source_layers_selected_parcelles_autour,
        ...this.source_layers_selected_parcelle,
      ]
    },
    setOpacityLayer(layer, opacity) {
      let fill_opacity = 0.3 - (0.2 - parseInt(opacity, 10) / 10000)

      if (opacity === 0) {
        fill_opacity = 0
      } else if (opacity === 100) {
        fill_opacity = 0.3
      }
      this.map.setPaintProperty(
        layer[1],
        'fill-opacity',
        fill_opacity, // 0.3
      )
      this.map.setPaintProperty(
        layer[2],
        'line-opacity',
        parseInt(opacity, 10) / 100, // 1
      )
    },
  },
}
