<template>
  <div class="mt-1 w-full">
    <div class="flex justify-between mb-2 items-center">
      <div class="flex gap-4">
        <img
          src="/images/drag-reorder.svg"
          alt=""
          class="w-4 sm:mr-2 handle cursor-move"
          v-if="!isReadOnly && topic.label != 'Tableau Recap'"
        />
        <div
          class="capitalize text-lg text-gray-800 tracking-wide font-extrabold font-main mr-2 sm-ws:pt-0"
        >
          {{ topic.label }}
        </div>
      </div>
      <div
        v-if="topic.label != 'Tableau Recap' && isLocked"
        class="font-semibold bg-gray-300 py-2 px-4 rounded-lg text-lg"
      >
        <img src="/images/lock.svg" alt="" class="w-6 h-6" />
      </div>
      <button
        v-if="!isReadOnly && topic.label != 'Tableau Recap' && !isLocked"
        @click="deleteTopic"
        class="font-semibold bg-red-500 hover:bg-red-600 py-2 px-4 rounded-lg text-lg"
      >
        <img src="/images/trash.svg" alt="" class="w-6 h-6" />
      </button>
    </div>
    <accordion
      class="w-full rounded-lg accordion-ipad bg-white"
      v-if="topic.template.hasOwnProperty('data')"
      :is_active_by_default="active_accordion"
    >
      <accordion-item class="shadow-box accordion-item relative">
        <template slot="accordion-button">
          <div
            @click="activingAccordionInfo()"
            class="h-8 w-8 rounded-full flex justify-center items-center self-center transition-all duration-300 absolute top-3 right-2 z-50"
            :class="{
              'bg-promy-green-200': active_accordion === true,
            }"
          >
            <img
              src="/images/DossierPromoteur/up-arrow-gray.svg"
              class="h-3 w-3 transition-all duration-300"
              :class="{
                'arrow-down': active_accordion !== true,
                'arrow-up': active_accordion === true,
              }"
            />
          </div>
        </template>

        <template slot="accordion-trigger">
          <a
            class="flex justify-between md:flex-wrap w-full py-3 px-5 cursor-pointer"
            v-if="!active_accordion"
            @click="activingAccordionInfo()"
          >
            <div class="flex justify-between flex-grow">
              <div
                class="capitalize text-xl text-gray-800 tracking-wide font-extrabold font-main mr-2 sm-ws:pt-0"
              >
                Total
              </div>
              <div
                class="flex justify-between w-1/2 mr-8"
                :key="summaryRefreshToken"
              >
                <div
                  class="flex flex-col gap-2"
                  v-for="item in summary()"
                  :key="item.label"
                >
                  <div
                    class="capitalize leading-none text-base text-gray-400 tracking-wide font-extrabold mr-2 sm-ws:pt-0"
                  >
                    {{ item.label }}
                  </div>
                  <div
                    class="capitalize leading-none text-lg text-gray-800 tracking-wide font-extrabold mr-2 sm-ws:pt-0"
                  >
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </template>
        <template slot="accordion-content">
          <div class="m-2 promy-sheet category-sheet" ref="spreadsheet0" />
        </template>
      </accordion-item>
    </accordion>
    <div v-else class="w-full flex gap-2">
      <div class="w-full flex flex-col gap-2">
        <div class="w-full promy-sheet recap-sheet" ref="spreadsheet0" />
        <div class="w-full promy-sheet recap-sheet" ref="spreadsheet3" />
      </div>
      <div class="w-full flex flex-col gap-2">
        <div class="w-full promy-sheet recap-sheet" ref="spreadsheet1" />
        <div class="w-full promy-sheet recap-sheet" ref="spreadsheet2" />
      </div>
    </div>
  </div>
</template>

<script>
import spreadSheet from '@/mixins/jspreadsheet/jspreadsheet.mixin'
import helpers from '@/mixins/helpers'
import { mapGetters } from 'vuex'

export default {
  props: {
    topic: {
      type: Object,
    },
    topicIndex: {
      type: Number,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active_accordion: true,
      summaryRefreshToken: 0,
    }
  },
  mixins: [spreadSheet, helpers],
  methods: {
    activingAccordionInfo() {
      this.active_accordion = !this.active_accordion
    },
    deleteTopic() {
      this.$store.commit(
        'bilanFinancier/REMOVE_PERCENTAGE',
        this.getOnlyCharacters(this.topic.label),
      )
      this.$emit('deleteTopic', this.topicIndex)
    },
    summary() {
      let data
      if (this.jExcelObjects[0]) {
        const headers = this.jExcelObjects[0].headers

        data = this.getSummary().map((item, index) => {
          return {
            label: headers[index].innerText,
            value: item,
          }
        })
        data.shift()
      }
      return this.jExcelObjects[0] ? data : []
    },
    refreshSummary() {
      this.summaryRefreshToken++
    },
    topicToSave() {
      const template = this.jExcelObjects.map((jo, index) => {
        return {
          columns: jo.getConfig().columns,
          data: this.getParsedDataWithoutFooter(this.getParsedData(jo)),
          footers: this.getParsedFooter(this.getParsedData(jo)),
        }
      })

      return {
        label: this.topic.label,
        locked: this.isLocked,
        template: template.length > 1 ? template : template[0],
      }
    },
  },
  mounted() {
    let jspreadsheetOptions
    if (Array.isArray(this.topic.template)) {
      jspreadsheetOptions = this.topic.template.map((t, index) => {
        const data = t.data && t.data.length > 0 ? t.data : [[]]
        return {
          data: data,
          columns: t.columns,
          name: this.getOnlyCharacters(this.topic.label) + (index + 1),
          footers: t.footers,
          readOnly: this.isReadOnly,
        }
      })
    } else {
      const data =
        this.topic.template.data && this.topic.template.data.length > 0
          ? this.topic.template.data
          : [[]]
      jspreadsheetOptions = [
        {
          data: data,
          columns: this.topic.template.columns,
          name: this.getOnlyCharacters(this.topic.label),
          footers: this.topic.template.footers,
          readOnly: this.isReadOnly,
        },
      ]
    }
    this.jExcelObjects = jspreadsheetOptions.map((options, index) =>
      this.$jspreadsheet(
        this.$refs[`spreadsheet${index}`],
        this.jExcelOptions(options),
      ),
    )

    this.isLocked = this.getIsLocked(this.jExcelObjects[0])
  },
  beforeDestroy() {
    this.percentages = []
    this.$jspreadsheet.destroy(this.$refs.spreadsheet0, true)
    if (this.$refs.spreadsheet1)
      this.$jspreadsheet.destroy(this.$refs.spreadsheet1, true)
    if (this.$refs.spreadsheet2)
      this.$jspreadsheet.destroy(this.$refs.spreadsheet2, true)
    if (this.$refs.spreadsheet3)
      this.$jspreadsheet.destroy(this.$refs.spreadsheet3, true)
  },
}
</script>
<style lang="scss">
.arrow-up {
  transform: rotate(0deg);
}
.arrow-down {
  transform: rotate(180deg);
}
.recap-sheet {
  thead {
    tr {
      td {
        @apply bg-promy-sheet-green-50 text-promy-sheet-green-500 border-promy-sheet-green-100;
      }
    }
  }
  tbody {
    td {
      &.footer:not(.jss_cursor) {
        @apply text-white font-bold bg-promy-sheet-green-500 #{!important};
      }
      &.jss_cursor {
        @apply text-black #{!important};
      }
    }
  }
  tfoot {
    tr {
      td {
        @apply bg-promy-sheet-green-500;
      }
    }
  }
}
.category-sheet {
  thead {
    tr {
      td {
        @apply bg-promy-sheet-purple-50 text-promy-sheet-purple-500 border-promy-sheet-purple-100;
      }
    }
  }
  tbody {
    td {
      &.footer:not(.jss_cursor) {
        @apply text-white font-bold bg-promy-sheet-purple-500 border-promy-sheet-purple-500 #{!important};
      }
      &.jss_cursor {
        @apply text-black #{!important};
      }
    }
  }
  tfoot {
    tr {
      td {
        @apply bg-promy-sheet-purple-500 border-promy-sheet-purple-500;
      }
    }
  }
}
.promy-sheet {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  .jss_table {
    .jss_content {
      @apply w-full;
    }
    table {
      width: -moz-available; /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      @apply bg-transparent border-b-0 border-r-0 table-auto mr-10 text-xs;
      colgroup {
        col {
          &:first-of-type {
            @apply hidden;
          }
        }
      }
      thead {
        tr {
          td {
            &:first-of-type {
              @apply hidden;
            }
            &:nth-of-type(2) {
              @apply border-l-0 rounded-tl-md;
            }
            &:last-of-type {
              @apply border-r-0 rounded-tr-md;
            }
            @apply border-t-0 border-r-0 border-b-0 text-left text-xs pl-4  #{!important};
          }
        }
      }
      tbody {
        tr {
          &:last-of-type {
            td {
              &:first-of-type {
                @apply hidden;
              }
              &:nth-of-type(2) {
                @apply rounded-bl-md;
              }
              &:last-of-type {
                @apply rounded-br-md;
              }
            }
          }
        }
        td {
          &:first-of-type {
            @apply hidden;
          }
          &.readonly {
            @apply text-black bg-promy-gray-200;
          }
          @apply border-promy-gray-300  text-left text-xs pl-4 #{!important};
        }
      }
      tfoot {
        tr {
          &:last-of-type {
            td {
              &:first-of-type {
                @apply hidden;
              }
              &:nth-of-type(2) {
                @apply rounded-bl-md;
              }
              &:last-of-type {
                @apply rounded-br-md;
              }
            }
          }
          td {
            &:first-of-type {
              @apply hidden;
            }
            &:nth-of-type(2) {
              @apply border-l-0;
            }
            &:last-of-type {
              @apply border-r-0;
            }
            @apply text-white text-sm  border-r-0 border-b-0 text-left pl-4 #{!important};
          }
        }
      }
    }
  }
}
</style>
