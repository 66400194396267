import transport from '@/plugins/axios'
const getDefaultState = () => {
  return {
    features_autour: [],
    parcelles_autour: [],
    base_layers: [],
    is_street: false,
    saved_sources: [],
    saved_layers: [],
    infos_gov: {},
    infos_cadastre: {},
    error_parcelle_not_found: null,
    markers_altimetrie: [],
    markers_walls: [],
    is_parcelle_aggregate: false,
    is_parcelle_changed: false,
    is_corner_changed: false,
  }
}
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    features_autour(state) {
      return state.features_autour
    },
    parcelles_autour(state) {
      return state.parcelles_autour
    },
    base_layers(state) {
      return state.base_layers
    },
    is_street(state) {
      return state.is_street
    },
    saved_sources(state) {
      return state.saved_sources
    },
    saved_layers(state) {
      return state.saved_layers
    },
    infos_gov(state) {
      return state.infos_gov
    },
    infos_cadastre(state) {
      return state.infos_cadastre
    },
    error_parcelle_not_found(state) {
      return state.error_parcelle_not_found
    },
    markers_altimetrie(state) {
      return state.markers_altimetrie
    },
    markers_walls(state) {
      return state.markers_walls
    },
    is_parcelle_aggregate(state) {
      return state.is_parcelle_aggregate
    },
    is_parcelle_changed(state) {
      return state.is_parcelle_changed
    },
    is_corner_changed(state) {
      return state.is_corner_changed
    },
  },
  mutations: {
    resetSelectedActionOnMap(state) {
      state.is_parcelle_aggregate = false
      state.is_parcelle_changed = false
      state.is_corner_changed = false
    },
    resetParcelle(state) {
      state.markers_altimetrie.forEach((marker) => {
        marker.remove()
      })
      state.markers_walls.forEach((marker) => {
        marker.remove()
      })
      Object.assign(state, getDefaultState())
    },

    FEATURES_AUTOUR(state, payload) {
      state.features_autour = payload
    },
    PARCELLES_AUTOUR(state, payload) {
      state.parcelles_autour = payload
    },
    BASE_LAYERS(state, payload) {
      state.base_layers = payload
    },
    IS_STREET(state, payload) {
      state.is_street = payload
    },
    SAVED_SOURCES(state, payload) {
      state.saved_sources = payload
    },
    SAVED_LAYERS(state, payload) {
      state.saved_layers = payload
    },
    INFOS_GOV(state, payload) {
      state.infos_gov = payload
    },
    INFOS_CADASTRE(state, payload) {
      state.infos_cadastre = payload
    },
    ERROR_PARCELLE_NOT_FOUND(state, payload) {
      state.error_parcelle_not_found = payload
    },
    MARKERS_ALTIMETRIE(state, payload) {
      if (Array.isArray(payload) === false)
        state.markers_altimetrie.push(payload)
      else state.markers_altimetrie = payload
    },
    MARKERS_WALLS(state, payload) {
      if (Array.isArray(payload) === false) state.markers_walls.push(payload)
      else state.markers_walls = payload
    },

    IS_PARCELLE_AGGREGATE(state, payload) {
      state.is_parcelle_aggregate = payload
    },
    IS_PARCELLE_CHANGED(state, payload) {
      state.is_parcelle_changed = payload
    },
    IS_CORNER_CHANGED(state, payload) {
      state.is_corner_changed = payload
    },
  },
  actions: {
    getElevation({ commit }, params) {
      return new Promise((resolve, reject) => {
        transport
          .get('address', {
            params: {
              lon: params.lon,
              lat: params.lat,
              type: 'elevation',
            },
          })
          .then((response) => {
            let temp_elevations = response.data.elevation.elevations
            let elevation =
              temp_elevations.length > 1 ? temp_elevations : temp_elevations[0]
            resolve(elevation)
          })
          .catch(() => {
            reject('altimetrie non disponible')
          })
      })
    },
  },
}
