import { openEditor, closeEditor, returnVal } from './helpers'

export const formatter = new Intl.NumberFormat('fr-Fr', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
})

function setCell(cell, value) {
  if (cell) {
    cell.innerText = formatter.format(value)
  }
}

export default {
  updateCell: function (cell, value, x, y, instance) {
    if (value == null || value === '') return value
    if (typeof value == 'string' && value[0] == '=') {
      setCell(cell, instance.executeFormula(value, x, y))
      return value
    }
    value = Number(value)
    setCell(cell, value)
    return value
  },

  createCell: function (cell, value, x, y, instance) {
    if (value == null || value === '') return value
    if (typeof value == 'string' && value[0] == '=') {
      setCell(cell, instance.executeFormula(value, x, y))
      return value
    }
    value = Number(value)
    setCell(cell, value)
    return value
  },

  openEditor: openEditor,

  closeEditor: closeEditor,

  get: returnVal,
}
