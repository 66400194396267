export const translations = {
  ADDRESS: 'ADRESSE',
  AND: 'ET',
  AREAS: 'ZONES',
  ASIN: 'ASIN',
  ASINH: 'ASINH',
  AVEDEV: 'ECART.MOYEN',
  AVERAGE: 'MOYENNE',
  BETADIST: 'LOI.BETA',
  BETAINV: 'BETA.INVERSE',
  BINOMDIST: 'LOI.BINOMIALE',
  CEILING: 'PLAFOND',
  CELL: 'CELLULE',
  CHAR: 'CAR',
  CHIDIST: 'LOI.KHIDEUX',
  CHIINV: 'KHIDEUX.INVERSE',
  CHITEST: 'TEST.KHIDEUX',
  CHOOSE: 'CHOISIR',
  CLEAN: 'EPURAGE',
  CODE: 'CODE',
  COLUMN: 'COLONNE',
  COLUMNS: 'COLONNES',
  CONCATENATE: 'CONCATENER',
  CONFIDENCE: 'INTERVALLE.CONFIANCE',
  COUNT: 'NB',
  COUNTA: 'NBVAL',
  COUNTBLANK: 'NB.VIDE',
  COUNTIF: 'NB.SI',
  CRITBINOM: 'CRITERE.LOI.BINOMIALE',
  DATE: 'DATE',
  DATEVALUE: 'DATEVAL',
  DAVERAGE: 'BDMOYENNE',
  DAY: 'JOUR',
  DAYS360: 'JOURS360',
  DB: 'DB',
  DCOUNT: 'BDNB',
  DCOUNTA: 'BDNBVAL',
  DDB: 'DDB',
  DEGREES: 'DEGRES',
  DEVSQ: 'SOMME.CARRES.ECARTS',
  DGET: 'BDLIRE',
  DMAX: 'BDMAX',
  DMIN: 'BDMIN',
  DOLLAR: 'FRANC',
  DPRODUCT: 'BDPRODUIT',
  DSTDEV: 'BDECARTYPE',
  DSTDEVP: 'BDECARTYPEP',
  DSUM: 'BDSOMME',
  DVAR: 'BDVAR',
  DVARP: 'BDVARP',
  EVEN: 'PAIR',
  EXACT: 'EXACT',
  EXPONDIST: 'LOI.EXPONENTIELLE',
  FACT: 'FACT',
  FDIST: 'LOI.F',
  FIND: 'TROUVE',
  FINV: 'INVERSE.LOI.F',
  FIXED: 'CTXT',
  FLOOR: 'PLANCHER',
  FORECAST: 'PREVISION',
  FREQUENCY: 'FREQUENCE',
  FTEST: 'TEST.F',
  FV: 'VC',
  GAMMADIST: 'LOI.GAMMA',
  GAMMAINV: 'LOI.GAMMA.INVERSE',
  GAMMALN: 'LNGAMMA',
  GEOMEAN: 'MOYENNE.GEOMETRIQUE',
  GROWTH: 'CROISSANCE',
  HARMEAN: 'MOYENNE.HARMONIQUE',
  HLOOKUP: 'RECHERCHEH',
  HOUR: 'HEURE',
  HYPGEOMDIST: 'LOI.HYPERGEOMETRIQUE',
  IF: 'SI',
  INDEX: 'INDEX',
  INDIRECT: 'INDIRECT',
  INFO: 'INFO',
  INTERCEPT: 'ORDONNEE.ORIGINE',
  IPMT: 'INTPER',
  IRR: 'TRI',
  ISBLANK: 'ESTVIDE',
  ISERR: 'ESTERR',
  ISERROR: 'ESTERREUR',
  ISLOGICAL: 'ESTLOGIQUE',
  ISNA: 'ESTNA',
  ISNONTEXT: 'ESTNONTEXTE',
  ISNUMBER: 'ESTNUM',
  ISPMT: 'ISPMT',
  ISREF: 'ESTREF',
  ISTEXT: 'ESTTEXTE',
  KURT: 'KURTOSIS',
  LARGE: 'GRANDE.VALEUR',
  LEFT: 'GAUCHE',
  LEN: 'NBCAR',
  LINEST: 'DROITEREG',
  LOGEST: 'LOGREG',
  LOGINV: 'LOI.LOGNORMALE.INVERSE',
  LOGNORMDIST: 'LOI.LOGNORMALE',
  LOOKUP: 'RECHERCHE',
  LOWER: 'MINUSCULE',
  MATCH: 'EQUIV',
  MAX: 'MAX',
  MDETERM: 'DETERMAT',
  MEDIAN: 'MEDIANE',
  MID: 'STXT',
  MIN: 'MIN',
  MINUTE: 'MINUTE',
  MINVERSE: 'INVERSEMAT',
  MIRR: 'TRIM',
  MMULT: 'PRODUITMAT',
  MODE: 'MODE',
  MONTH: 'MOIS',
  NA: 'NA',
  NEGBINOMDIST: 'LOI.BINOMIALE.NEG',
  NORMDIST: 'LOI.NORMALE',
  NORMINV: 'LOI.NORMALE.INVERSE',
  NORMSDIST: 'LOI.NORMALE.STANDARD',
  NORMSINV: 'LOI.NORMALE.STANDARD.INVERSE',
  NOT: 'NON',
  NOW: 'MAINTENANT',
  NPV: 'VAN',
  ODD: 'IMPAIR',
  OFFSET: 'DECALER',
  OR: 'OU',
  PERCENTILE: 'CENTILE',
  PERCENTRANK: 'RANG.POURCENTAGE',
  PMT: 'VPM',
  POWER: 'PUISSANCE',
  PPMT: 'PRINCPER',
  PRODUCT: 'PRODUIT',
  PROPER: 'NOMPROPRE',
  PV: 'VA',
  QUARTILE: 'QUARTILE',
  RADIANS: 'RADIANS',
  RAND: 'ALEA',
  RANK: 'RANG',
  RATE: 'TAUX',
  REPLACE: 'REMPLACER',
  RIGHT: 'DROITE',
  ROMAN: 'ROMAIN',
  ROUND: 'ARRONDI',
  ROUNDDOWN: 'ARRONDI.INF',
  ROUNDUP: 'ARRONDI.SUP',
  ROW: 'LIGNE',
  ROWS: 'LIGNES',
  RSQ: 'COEFFICIENT.DETERMINATION',
  SEARCH: 'CHERCHE',
  SECOND: 'SECONDE',
  SIGN: 'SIGNE',
  SIN: 'SIN',
  SINH: 'SINH',
  SLN: 'AMORLIN',
  SLOPE: 'PENTE',
  SMALL: 'PETITE.VALEUR',
  SQRT: 'RACINE',
  STANDARDIZE: 'CENTREE.REDUITE',
  STDEV: 'ECARTYPE',
  STDEVP: 'ECARTYPEP',
  STEYX: 'ERREUR.TYPE.XY',
  SUM: 'SOMME',
  SUMIF: 'SOMME.SI',
  SUMPRODUCT: 'SOMMEPROD',
  SUMSQ: 'SOMME.CARRES',
  SUMX2MY2: 'SOMME.X2MY2',
  SUMX2PY2: 'SOMME.X2PY2',
  SUMXMY2: 'SOMME.XMY2',
  SYD: 'SYD',
  TDIST: 'LOI.STUDENT',
  TEXT: 'TEXTE',
  TIME: 'TEMPS',
  TIMEVALUE: 'TEMPSVAL',
  TINV: 'LOI.STUDENT.INVERSE',
  TODAY: 'AUJOURDHUI',
  TRANSPOSE: 'TRANSPOSE',
  TREND: 'TENDANCE',
  TRIM: 'SUPPRESPACE',
  TRIMMEAN: 'MOYENNE.REDUITE',
  TRUNC: 'TRONQUE',
  TTEST: 'TEST.STUDENT',
  TYPE: 'TYPE',
  UPPER: 'MAJUSCULE',
  VALUE: 'CNUM',
  VDB: 'VDB',
  VLOOKUP: 'RECHERCHEV',
  WEEKDAY: 'JOURSEM',
  YEAR: 'ANNEE',
  ZTEST: 'TEST.Z',
}
