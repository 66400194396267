<template>
  <Modal v-if="addTopicModal">
    <slot slot="header">
      <h1 class="text-promy-purple-400 font-black text-xl font-main">
        Ajout de topic :
      </h1>
    </slot>
    <slot slot="body">
      <ValidationObserver v-slot="{ invalid }" ref="validationObserver">
        <div class="w-1/2 md:w-2/3 sm:w-full">
          <pro-input
            name="label"
            requirements="required"
            v-model="topicToAdd.label"
            label="Libellé"
          />
        </div>
      </ValidationObserver>
    </slot>
    <slot slot="footer">
      <button
        class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8 mr-4"
        @click="toggleAddTopicModal"
      >
        Annuler
      </button>
      <button
        class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main btn-green px-6 py-2 sm:w-full md:mt-8"
        @click="addTopic"
      >
        Ajouter
      </button>
    </slot>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      addTopicModal: false,
      topicToAdd: {
        label: null,
        template: null,
      },
    }
  },
  methods: {
    toggleAddTopicModal() {
      this.addTopicModal = !this.addTopicModal
    },
    addTopic() {
      this.topicToAdd.template = {
        data: [
          [
            {
              value: '&nbsp;',
              column: 'label',
              rendered_value: '&nbsp;',
            },
          ],
        ],
        columns: this.$jspreadsheet.defaultSingleTableColumns,
        footers: this.$jspreadsheet.defaultSingleTableFooters,
      }

      if (this.topicToAdd.label != null) {
        this.$emit('addTopic', this.topicToAdd)
        this.toggleAddTopicModal()
        this.topicToAdd = {
          label: null,
          template: null,
        }
      }
    },
  },
}
</script>

<style></style>
