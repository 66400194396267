import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './modules/auth'
import sidebar from './modules/sidebar'
import subSidebar from './modules/subSidebar'
import dossierpromoteur from './modules/dossierpromoteur'
import parcelle from './modules/parcelle'
import defaultmap from './modules/defaultmap'
import conditionssuspensives from './modules/conditionssuspensives'
import questionsmatching from './modules/questionsmatching'
import dashboard from './modules/dashboard'
import poi from './modules/poi'
import cadastreOfficiel from './modules/cadastreOfficiel'
import localisation from './modules/localisation'
import etudeCommerciale from './modules/etudeCommerciale'
import loading from './modules/loading'
import risques from './modules/risques'
import partenaire from './modules/partenaire'
import manageDocuments from './modules/manageDocuments'
import abonnement from './modules/abonnement'
import roleAndPermission from './modules/roleAndPermission'
import cgu from './modules/cgu'
import bilanFinancier from './modules/bilanFinancier'
import sortBy from './modules/sortBy'
import userNavigationState from './modules/userNavigationState'
import delegation from './modules/delegation'
import offre from './modules/offre'
import deactivateTeam from './modules/deactivateTeam'

export default new Vuex.Store({
  modules: {
    auth,
    sidebar,
    dossierpromoteur,
    subSidebar,
    parcelle,
    defaultmap,
    conditionssuspensives,
    dashboard,
    questionsmatching,
    poi,
    cadastreOfficiel,
    localisation,
    etudeCommerciale,
    loading,
    risques,
    partenaire,
    manageDocuments,
    abonnement,
    roleAndPermission,
    cgu,
    bilanFinancier,
    sortBy,
    userNavigationState,
    delegation,
    offre,
    deactivateTeam,
  },
})
