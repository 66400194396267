import Vue from 'vue'

import jspreadsheet from 'jspreadsheet'
import jsuites from 'jsuites'
import customFrEuro from './jspreadsheet-editors/customFrEuro'
import customPercent from './jspreadsheet-editors/customPercent'
import customSquareMeter from './jspreadsheet-editors/customSquareMeter'
import { translations } from './jspreadsheet-editors/translation'
import formula from '@jspreadsheet/formula-pro'

import 'jspreadsheet/dist/jspreadsheet.css'
import 'jspreadsheet/dist/jspreadsheet.themes.css'
import 'jsuites/dist/jsuites.css'
import '@/assets/styles/spreadsheet.css'

export function getSpreadSheetByName(name) {
  return jspreadsheet.spreadsheet.find(
    (sheet) =>
      sheet.hasOwnProperty('worksheets') &&
      sheet.worksheets[0].getConfig().worksheetName.toUpperCase() ==
        name.toUpperCase(),
  ).worksheets[0]
}

export function getPrixDeVenteTotal() {
  const ws = getSpreadSheetByName('Tableau_Recap3')
  const data = ws.getData()
  const pdvt = parseFloat(
    ws
      .getValueFromCoords(1, data.length - 1, true)
      .split('&nbsp;')[0]
      .replace(/\ /gm, '')
      .replace(',', '.'),
  )
  return pdvt
}

var SUMCOL = function (instance, columnId, cell, onlyVisible) {
  var total = 0
  const self = parseFloat(cell.substring(1)) - 1

  for (var j = 0; j < instance.options.data.length; j++) {
    if (
      (!onlyVisible || !instance.results || instance.results.indexOf(j) >= 0) &&
      j != self
    ) {
      if (typeof columnId !== 'number') columnId = parseInt(this.x) + 1
      total +=
        instance.records[j][columnId - 1].v == ''
          ? 0
          : instance.records[j][columnId - 1].v
    }
  }
  return total
}

var SUMCOLWITHOUTFORMAT = function (instance, columnId, onlyVisible) {
  var total = 0
  for (var j = 0; j < instance.options.data.length; j++) {
    if (!onlyVisible || !instance.results || instance.results.indexOf(j) >= 0) {
      if (typeof columnId !== 'number') columnId = parseInt(this.x) + 1
      total +=
        instance.records[j][columnId - 1].v == ''
          ? 0
          : instance.records[j][columnId - 1].v
    }
  }
  return total
}

var AVGCOL = function (instance, columnId, cell, onlyVisible) {
  var total = 0
  var rows = 0
  const self = parseFloat(cell.substring(1)) - 1
  for (var j = 0; j < instance.options.data.length; j++) {
    if (
      !onlyVisible ||
      !instance.results ||
      (instance.results.indexOf(j) >= 0 && j != self)
    ) {
      if (typeof columnId !== 'number') columnId = parseInt(this.x) + 1

      const value = parseFloat(instance.records[j][columnId - 1].v)
      if (!isNaN(value)) {
        total += parseFloat(instance.records[j][columnId - 1].v)
        rows++
      }
    }
  }

  return rows == 0 ? 0 : total / rows
}

var JAUGE = function (v1, v2) {
  const sheet = v1.split('.')[0]
  const x = parseFloat(v1.split('.')[1].split(':')[0])
  const y = parseFloat(v1.split('.')[1].split(':')[1])
  const calculatedV1 = getSpreadSheetByName(sheet)
    ? SUMCOLWITHOUTFORMAT(getSpreadSheetByName(sheet), x + 1, true)
    : 0
  const percent = (calculatedV1 / v2) * 100
  return `<div class="w-full bg-gray-200 h-1"><div class="bg-blue-600 h-1" style="width: ${percent}%"></div></div>`
}

formula.setFormula({ SUMCOL })
formula.setFormula({ SOMMECOL: SUMCOL })
formula.setFormula({ AVGCOL })
formula.setFormula({ MOYCOL: AVGCOL })
formula.setFormula({ JAUGE })

formula.adjustPrecision = true

Object.keys(translations).forEach(function (formulaNameEn) {
  formula[translations[formulaNameEn]] = formula[formulaNameEn]
})

Vue.prototype.$jsuites = jsuites
Vue.prototype.$jspreadsheet = jspreadsheet
Vue.prototype.$jspreadsheet.editors = {
  ...Vue.prototype.$jspreadsheet.editors,
  customPercent: customPercent,
  customFrEuro: customFrEuro,
  customSquareMeter: customSquareMeter,
}

Vue.prototype.$jspreadsheet.setLicense(process.env.VUE_APP_JSPREADSHEET_LICENSE)
Vue.prototype.$jspreadsheet.setExtensions({ formula })

Vue.prototype.$jspreadsheetFormula = formula
Vue.prototype.$jspreadsheet.prixVenteTotal = getPrixDeVenteTotal

Vue.prototype.$jspreadsheet.defaultSingleTableColumns = [
  {
    type: 'text',
    title: 'label',
    width: 381,
  },
  {
    type: 'customPercent',
    title: 'Pourcentage',
    width: '100px',
    locale: 'fr-FR',
    options: {
      style: 'percent',
      decimal: ',',
      maximumFractionDigits: 2,
    },
  },
  {
    type: 'customFrEuro',
    title: 'H.T.',
    width: 126,
    locale: 'fr-FR',
    options: {
      style: 'currency',
      locale: 'fr-FR',
      decimal: ',',
      currency: 'EUR',
      maximumFractionDigits: 2,
    },
    inputmode: 'decimal',
  },
  {
    type: 'customPercent',
    title: 'T.V.A.',
    width: 125,
    locale: 'fr-FR',
    options: {
      style: 'percent',
      decimal: ',',
      maximumFractionDigits: 2,
    },
  },
  {
    type: 'customFrEuro',
    title: 'Prix T.V.A.',
    width: 134,
    locale: 'fr-FR',
    options: {
      style: 'currency',
      decimal: ',',
      currency: 'EUR',
      maximumFractionDigits: 2,
    },
  },
  {
    type: 'customFrEuro',
    title: 'T.T.C.',
    width: '200px',
    locale: 'fr-FR',
    options: {
      style: 'currency',
      decimal: ',',
      currency: 'EUR',
      maximumFractionDigits: 2,
    },
  },
]

Vue.prototype.$jspreadsheet.defaultSingleTableFooters = [
  [
    {
      cell_options: {
        type: 'text',
      },
      value: 'Total',
      rendered_value: 'Total',
    },
    {
      cell_options: {
        type: 'customPercent',
      },
      value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
      rendered_value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
    },
    {
      cell_options: {
        type: 'customFrEuro',
      },
      value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
      rendered_value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
    },
    {
      cell_options: {
        type: 'customPercent',
      },
      value: '=MOYCOL(TABLE(),COLUMN(),CELL(),TRUE)',
      rendered_value: '=MOYCOL(TABLE(),COLUMN(),CELL(),TRUE)',
    },
    {
      cell_options: {
        type: 'customFrEuro',
      },
      value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
      rendered_value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
    },
    {
      cell_options: {
        type: 'customFrEuro',
      },
      value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
      rendered_value: '=SOMMECOL(TABLE(),COLUMN(),CELL(),TRUE)',
    },
  ],
]
