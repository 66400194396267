import jsuites from 'jsuites'
import { editorKeyPressEvent, returnVal } from './helpers'

export const formatter = new Intl.NumberFormat('fr-Fr', {
  style: 'percent',
  decimal: ',',
  maximumFractionDigits: 2,
})

function setCell(cell, value) {
  if (cell) {
    cell.innerText = formatter.format(value)
  }
}

export default {
  updateCell: function (cell, value, x, y, instance) {
    if (value == null || value === '') return value
    if (typeof value == 'string' && value[0] == '=') {
      setCell(cell, instance.executeFormula(value, x, y))
      return value
    }
    value = Number(value)
    setCell(cell, value)
    return value
  },

  createCell: function (cell, value, x, y, instance) {
    if (value == null || value === '') return value
    if (typeof value == 'string' && value[0] == '=') {
      setCell(cell, instance.executeFormula(value, x, y))
      return value
    }
    value = Number(value)
    setCell(cell, value)
    return value
  },

  openEditor: function (cell, value, x, y, instance) {
    instance.parent.input.onblur = function () {
      instance.closeEditor(cell, true)
    }

    instance.parent.input.onkeypress = (e) => editorKeyPressEvent(e, instance)

    if (value) {
      instance.parent.input.innerText =
        typeof value == 'string' && value[0] == '='
          ? value
          : Number(value) * 100
    }
    jsuites.focus(instance.parent.input)
  },

  closeEditor: function (cell, save, x, y, instance) {
    instance.parent.input.onkeypress = (e) => {}
    let value
    if (instance.parent.input.innerText[0] == '=') {
      return instance.parent.input.innerText
    }
    if (save) {
      value = Number(instance.parent.input.innerText.replace(',', '.')) / 100
    } else {
      value = ''
    }
    return value
  },

  get: returnVal,
}
