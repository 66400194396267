import { mapGetters } from 'vuex'
import { getSpreadSheetByName } from '@/plugins/jspreadsheet'
import { formatter as customFrEuroFormatter } from '@/plugins/jspreadsheet-editors/customFrEuro'
import { formatter as customPercentFormatter } from '@/plugins/jspreadsheet-editors/customPercent'
import { format as customSquareMeterFormat } from '@/plugins/jspreadsheet-editors/customSquareMeter'
export default {
  data() {
    return {
      data: null,
      isLocked: false,
      jExcelObjects: [],
      percentages: [],
    }
  },
  computed: {
    ...mapGetters({
      storePercentages: 'bilanFinancier/getPercentages',
      storeTotalPercentages: 'bilanFinancier/getTotalPercentages',
      syncPvtIndex: 'bilanFinancier/getSyncPvtIndex',
    }),
  },
  watch: {
    syncPvtIndex: {
      handler: function () {
        if (this.getOnlyCharacters(this.topic.label) !== 'Tableau_Recap') {
          this.syncCalulcationToPVTChange()
        }
      },
    },
  },
  methods: {
    getSummary() {
      const data = this.jExcelObjects[0].getData()
      const footer = data[data.length - 1]
      return footer.map(
        (item, i) =>
          this.jExcelObjects[0].getCellFromCoords(i, data.length - 1).outerText,
      )
    },
    dataHasFooter(data) {
      return Object.values(data).some((cell) => cell.footer_cell)
    },
    isSameTopicSheet(name) {
      return name == this.getOnlyCharacters(this.topic.label)
    },
    getIsLocked(worksheet) {
      return Object.values(worksheet.getCells()).some(
        (c) => c.hasOwnProperty('locked') && c.locked,
      )
    },
    getRowIsLocked(worksheet, y) {
      const rowCells = Object.keys(worksheet.getCells())
        .filter((c) => parseInt(c.substring(1)) == parseInt(y) + 1)
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: worksheet.getCells()[key],
          })
        }, {})

      return Object.values(rowCells).some(
        (c) => c.hasOwnProperty('locked') && c.locked,
      )
    },
    parseInputData(data) {
      if (Array.isArray(data))
        return data.map((row) => {
          return row.map((item) => item.value)
        })
      return []
    },
    parseInputCells(data) {
      if (Array.isArray(data))
        return data.reduce((obj, row, rowIndex) => {
          return {
            ...obj,
            ...row.reduce((rowObj, cell, cellIndex) => {
              const key = `${String.fromCharCode(65 + cellIndex)}${
                rowIndex + 1
              }`

              const options = {
                ...cell.cell_options,
                readOnly:
                  cell.cell_options &&
                  cell.cell_options.hasOwnProperty('locked') &&
                  cell.cell_options.locked &&
                  typeof cell.value == 'string' &&
                  cell.value[0] == '=',
              }

              return {
                ...rowObj,
                ...(cell.cell_options && {
                  [key]: options,
                }),
              }
            }, {}),
          }
        }, {})
      return []
    },
    getParsedData(sheet) {
      return sheet.getData().map((row, rowIndex) => {
        return row.map((value, cellIndex) => {
          const column = sheet.getColumn(cellIndex)
          const isFormatted =
            (column.mask && typeof column.mask == 'object') ||
            (column.locale &&
              column.options &&
              typeof column.options == 'object')
          let rendered_value = isFormatted
            ? sheet
                .getCellFromCoords(cellIndex, rowIndex)
                .outerText.substring(
                  0,
                  sheet.getCellFromCoords(cellIndex, rowIndex).outerText
                    .length - 1,
                )
                .replace(',', '.')
                .replace(/\s/g, '')
            : sheet.getCellFromCoords(cellIndex, rowIndex).outerText
          rendered_value = isNaN(rendered_value)
            ? rendered_value
            : parseFloat(rendered_value)
          const cell_options =
            sheet.getCells()[
              String.fromCharCode(65 + parseInt(cellIndex)) +
                (parseInt(rowIndex) + 1)
            ]

          return {
            value: value,
            column: column.title,
            unit_options: column.options,
            rendered_value: rendered_value,
            ...(cell_options && { cell_options: cell_options }),
          }
        })
      })
    },
    getParsedDataWithoutFooter(parsedData) {
      return parsedData.filter((row) => {
        return !(
          row[0].cell_options &&
          row[0].cell_options.hasOwnProperty('footer_cell') &&
          row[0].cell_options.footer_cell
        )
      })
    },
    getParsedFooter(parsedData) {
      return parsedData.filter((row) => {
        return (
          row[0].cell_options &&
          row[0].cell_options.hasOwnProperty('footer_cell') &&
          row[0].cell_options.footer_cell
        )
      })
    },
    readOnlyColumns(columns) {
      return columns.map((column) => {
        return {
          ...column,
          readOnly: true,
        }
      })
    },
    prixTvaAndTTCColumns(columns) {
      return columns.map((column) => {
        return column.title == 'T.T.C.' || column.title == 'Prix T.V.A.'
          ? {
              ...column,
              readOnly: true,
            }
          : column
      })
    },
    setByUserCell(worksheet, x) {
      if (x > 0) {
        const columnOptions = worksheet.getColumn(x)
        const otherColumnOptions =
          x > 1 ? worksheet.getColumn(x - 1) : worksheet.getColumn(x + 1)
        const cell = worksheet.getSelected(true, false)[0]
        const otherCell = cell.includes('C')
          ? cell.replace('C', 'B')
          : cell.replace('B', 'C')

        worksheet.setCells(otherCell, {
          ...otherColumnOptions,
        })

        worksheet.setCells(cell, {
          ...columnOptions,
          by_user: true,
        })
      }
    },
    getColsFromRange(colrange) {
      let colRange = colrange.map((c) => c.charCodeAt())

      if (colRange[1] - colRange[0] == 0) {
        return [colrange[0]]
      }

      let cols = []

      for (let i = colRange[0]; i <= colRange[1]; i++) {
        cols.push(String.fromCharCode(i))
      }

      return cols
    },
    getCellsFromRange(cellrange) {
      const rowRange = cellrange.split(':').map((s) => parseInt(s[1]))
      let colRange = cellrange.split(':').map((s) => s[0])
      let cells = []
      this.getColsFromRange(colRange).forEach((col) => {
        for (let i = rowRange[0]; i <= rowRange[1]; i++) {
          cells.push(`${col}${i}`)
        }
      })
      return cells
    },
    extractCellFromFormula(formula) {
      return formula
        .substring(1)
        .split('(')
        .map((s) => s.split(')'))
        .flat(Infinity)
        .map((s) => s.split('*'))
        .flat(Infinity)
        .map((s) => s.split('+'))
        .flat(Infinity)
        .map((s) => s.split('-'))
        .flat(Infinity)
        .map((s) => s.split('/'))
        .flat(Infinity)
        .map((s) => s.split('**'))
        .flat(Infinity)
        .map((s) => s.split('%'))
        .flat(Infinity)
        .filter((s) => /[A-Z][0-9]+/.test(s))
    },
    lockSelfCell(worksheet, cell) {
      this.lockCell(worksheet, cell)
      worksheet.setReadOnly(cell, true)
    },
    lockCell(worksheet, cell) {
      const columnOptions = worksheet.getColumn(cell[0].charCodeAt() - 65)
      if (
        typeof worksheet.getValue(cell, false) == 'string' &&
        worksheet.getValue(cell, false)[0] == '='
      ) {
        let cells = this.extractCellFromFormula(worksheet.getValue(cell, false))
        cells.forEach((cell) => {
          if (cell.includes('!')) {
            const splited = cell.split('!')
            const foreignWorksheet = getSpreadSheetByName(splited[0])
            if (splited[1].includes(':')) {
              this.getCellsFromRange(splited[1]).forEach((c) =>
                this.lockCell(foreignWorksheet, c),
              )
            } else {
              this.lockCell(foreignWorksheet, splited[1])
            }
          } else {
            if (cell.includes(':')) {
              this.getCellsFromRange(cell).forEach((c) =>
                this.lockCell(worksheet, c),
              )
            } else {
              this.lockCell(worksheet, cell)
            }
          }
        })
      }
      worksheet.setCells(cell, {
        ...columnOptions,
        locked: true,
      })
      this.$emit('cellLockChange')
    },
    unLockCell(worksheet, cell) {
      const columnOptions = worksheet.getColumn(cell[0].charCodeAt() - 65)
      if (
        typeof worksheet.getValue(cell, false) == 'string' &&
        worksheet.getValue(cell, false)[0] == '='
      ) {
        let cells = this.extractCellFromFormula(worksheet.getValue(cell, false))
        cells.forEach((cell) => {
          if (cell.includes('!')) {
            const splited = cell.split('!')
            const foreignWorksheet = getSpreadSheetByName(splited[0])
            if (splited[1].includes(':')) {
              this.getCellsFromRange(splited[1]).forEach((c) =>
                this.unLockCell(foreignWorksheet, c),
              )
            } else {
              this.unLockCell(foreignWorksheet, splited[1])
            }
          } else {
            if (cell.includes(':')) {
              this.getCellsFromRange(cell).forEach((c) =>
                this.unLockCell(worksheet, c),
              )
            } else {
              this.unLockCell(worksheet, cell)
            }
          }
        })
      }
      worksheet.setReadOnly(cell, false)
      worksheet.setCells(cell, {
        ...columnOptions,
      })
      this.$emit('cellLockChange')
    },
    checkIfByUserCell(worksheet, x, y) {
      let cell = String.fromCharCode(65 + parseInt(x)) + (parseInt(y) + 1)
      cell = worksheet.getCells(cell)
      return cell && cell.hasOwnProperty('by_user') && cell.by_user
    },
    setCellUnit(worksheet, cell, type) {
      let value = worksheet
        .getValue(cell, true)
        .replace(/\ /gm, '&nbsp;')
        .replace(' ', '&nbsp;')
        .split('&nbsp;')
      if (value.length > 1) value.pop()
      value = parseFloat(value.join(''))
      worksheet.setCells(cell, { type: type })
      let valueToSet
      switch (type) {
        case 'customSquareMeter':
          valueToSet = customSquareMeterFormat(value)
          break
        case 'customFrEuro':
          valueToSet = customFrEuroFormatter.format(value)
          break
        case 'customPercent':
          valueToSet = customPercentFormatter.format(value / 100)
          break
        case 'numeric':
          valueToSet = value
          break
      }
      worksheet.getCell(cell).innerText = valueToSet
    },
    setFooterCellUnit(worksheet, x, y, type) {
      const value = worksheet.getFooterValue(x, y)
      switch (type) {
        case 'customSquareMeter':
          worksheet.setFooterValue(x, y, customSquareMeterFormat(value))
          break
        case 'customFrEuro':
          worksheet.setFooterValue(x, y, customFrEuroFormatter.format(value))
          break
        case 'customPercent':
          worksheet.setFooterValue(x, y, customPercentFormatter.format(value))
          break
        case 'numeric':
          worksheet.setFooterValue(x, y, value)
          break
      }
    },
    setAcquisitionCell(worksheet, cell) {
      const columnOptions = worksheet.getColumn(cell[0].charCodeAt() - 65)
      worksheet.setCells(cell, {
        ...columnOptions,
        acquisition_cell: true,
      })
    },
    unsetAcquisitionCell(worksheet, cell) {
      const columnOptions = worksheet.getColumn(cell[0].charCodeAt() - 65)
      worksheet.setCells(cell, {
        ...columnOptions,
      })
    },
    addPercentage(worksheet, record, name) {
      const key = `${String.fromCharCode(65 + parseInt(record.x))}${
        parseInt(record.y) + 1
      }`
      const cell = worksheet.getCells(key)
      if (
        name &&
        record.x == '1' &&
        !(cell && cell.hasOwnProperty('footer_cell') && cell.footer_cell)
      ) {
        const percentage = {
          sheet: name,
          row: record.y,
          value: record.value,
        }
        const index = this.percentages.findIndex((p) => {
          return p.sheet == percentage.sheet && p.row == percentage.row
        })
        if (index == -1) {
          if (this.storeTotalPercentages + percentage.value > 1) {
            return false
          } else {
            this.percentages.push(percentage)
            this.$store.commit('bilanFinancier/ADD_PERCENTAGE', {
              sheet: name,
              value: this.totalPercentages(),
            })
          }
        } else {
          const total =
            this.percentages.filter((p, i) => i !== index).length > 0
              ? this.percentages
                  .filter((p, i) => i !== index)
                  .reduce((t, p) => {
                    return {
                      value: t.value + p.value,
                    }
                  }).value
              : 0
          const storeTotal =
            this.storePercentages.filter((p) => p.sheet !== name).length > 0
              ? this.storePercentages
                  .filter((p) => p.sheet !== name)
                  .reduce((t, p) => {
                    return {
                      value: t.value + p.value,
                    }
                  }).value
              : 0

          if (total + storeTotal + percentage.value > 1) {
            return false
          } else {
            this.percentages[index] = percentage
            this.$store.commit('bilanFinancier/ADD_PERCENTAGE', {
              sheet: name,
              value: this.totalPercentages(),
            })
          }
        }
        return true
      }
    },
    removePercentage(worksheet, row) {
      const name = worksheet.getConfig().worksheetName
      const index = this.percentages.findIndex((p) => {
        return p.sheet == name && p.row == row
      })
      if (index !== -1) {
        this.$store.commit('bilanFinancier/ADD_PERCENTAGE', {
          sheet: name,
          value: this.totalPercentages() - this.percentages[index].value,
        })
        this.percentages.splice(index, 1)
      }
    },
    totalPercentages() {
      return this.percentages.length > 0
        ? this.percentages.reduce((total, p) => {
            return {
              value: total.value + p.value,
            }
          }).value
        : 0
    },
    rowCalculations(worksheet, record, name = null) {
      if (!name) name = worksheet.getConfig().worksheetName
      if (this.isSameTopicSheet(name)) {
        const prixVenteTotal = this.$jspreadsheet.prixVenteTotal()
        const HT = parseFloat((prixVenteTotal * record.value).toFixed(2))

        let tva = worksheet.getValueFromCoords(parseInt(record.x) + 2, record.y)
        tva = typeof tva == 'string' ? 0.2 : tva
        worksheet.setValueFromCoords(parseInt(record.x) + 1, record.y, HT, true)

        worksheet.setValueFromCoords(
          parseInt(record.x) + 2,
          record.y,
          tva,
          true,
        )

        worksheet.setValueFromCoords(
          parseInt(record.x) + 3,
          record.y,
          HT * tva,
          true,
        )
        worksheet.setValueFromCoords(
          parseInt(record.x) + 4,
          record.y,
          HT + HT * tva,
          true,
        )
      }
    },
    reverseRowCalculations(worksheet, record) {
      const name = worksheet.getConfig().worksheetName

      if (this.isSameTopicSheet(name)) {
        const prixVenteTotal = this.$jspreadsheet.prixVenteTotal()
        const percentage = parseFloat(
          (record.value / prixVenteTotal).toFixed(4),
        )
        const percentageRecord = {
          x: 1,
          y: record.y,
          value: percentage,
        }
        let tva = worksheet.getValueFromCoords(parseInt(record.x) + 1, record.y)

        tva = typeof tva == 'string' ? 0.2 : tva

        if (this.addPercentage(worksheet, percentageRecord, name)) {
          worksheet.setValueFromCoords(
            parseInt(record.x) - 1,
            record.y,
            percentage,
            true,
          )

          worksheet.setValueFromCoords(
            parseInt(record.x) + 1,
            record.y,
            tva,
            true,
          )
          worksheet.setValueFromCoords(
            parseInt(record.x) + 2,
            record.y,
            record.value * tva,
            true,
          )

          worksheet.setValueFromCoords(
            parseInt(record.x) + 3,
            record.y,
            record.value + record.value * tva,
            true,
          )
        } else {
          this.resetRowCalculations(worksheet, record.y)
        }
      }
    },
    resetRowCalculations(worksheet, row) {
      this.$toaster.warning(
        'Impossible de dépasser 100% pour le total des pourcentages !',
      )
      worksheet.setValueFromCoords(1, row, 0, true)
      worksheet.setValueFromCoords(2, row, 0, true)
      worksheet.setValueFromCoords(4, row, 0, true)
      worksheet.setValueFromCoords(5, row, 0, true)
    },
    calculateTVA(worksheet, record) {
      let HT = worksheet.getValueFromCoords(parseInt(record.x) - 1, record.y)

      HT = typeof HT == 'string' ? 0 : HT

      worksheet.setValueFromCoords(
        parseInt(record.x) + 1,
        record.y,
        HT * record.value,
        true,
      )

      worksheet.setValueFromCoords(
        parseInt(record.x) + 2,
        record.y,
        HT + HT * record.value,
        true,
      )
    },
    syncCalulcationToPVTChange() {
      let worksheets = this.$jspreadsheet.spreadsheet
        .filter((spreadsheet) => {
          const name = spreadsheet.worksheets[0].getConfig().worksheetName
          return !name.includes('Tableau_Recap')
        })
        .map((ws) => ws.worksheets[0])

      worksheets.forEach((worksheet) => {
        worksheet.rows.forEach((row, index) => {
          const percentageRecord = {
            x: 1,
            y: index,
            value: worksheet.getValueFromCoords(1, index),
          }
          const HTRecord = {
            x: 2,
            y: index,
            value: worksheet.getValueFromCoords(2, index),
          }

          if (
            typeof percentageRecord.value == 'string' &&
            percentageRecord.value[0] == '='
          )
            percentageRecord.value = worksheet.executeFormula(
              percentageRecord.value,
              percentageRecord.x,
              percentageRecord.y,
            )

          if (typeof HTRecord.value == 'string' && HTRecord.value[0] == '=')
            HTRecord.value = worksheet.executeFormula(
              HTRecord.value,
              HTRecord.x,
              HTRecord.y,
            )

          if (
            this.checkIfByUserCell(worksheet, HTRecord.x, HTRecord.y) &&
            !this.isFooterSelected(worksheet, HTRecord.y)
          ) {
            this.reverseRowCalculations(worksheet, HTRecord)
          } else if (
            typeof percentageRecord.value != 'string' &&
            !this.isFooterSelected(worksheet, percentageRecord.y)
          ) {
            this.rowCalculations(worksheet, percentageRecord)
          }
        })
      })
    },
    autoCalculations(worksheet, record) {
      const name = worksheet.getConfig().worksheetName
      if (!this.isFooterSelected(worksheet, record.y)) {
        if (!name.includes('Tableau_Recap')) {
          if (typeof record.value == 'string' && record.value[0] == '=')
            record.value = worksheet.executeFormula(record.value)
          switch (record.x) {
            case 1:
              if (this.addPercentage(worksheet, record, name)) {
                this.rowCalculations(worksheet, record, name)
              } else {
                this.resetRowCalculations(worksheet, record.y)
              }
              break
            case 2:
              this.reverseRowCalculations(worksheet, record)
              break
            case 3:
              this.calculateTVA(worksheet, record)
              break
            default:
              break
          }
        } else if (name == 'Tableau_Recap3') {
          this.$store.commit('bilanFinancier/DISPATCH_SYNC_PVT')
          // this.syncCalulcationToPVTChange()
        }
      }
    },
    isFooterSelected(worksheet, y) {
      const rowCells = Object.keys(worksheet.getCells())
        .filter((c) => parseInt(c.substring(1)) == parseInt(y) + 1)
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: worksheet.getCells()[key],
          })
        }, {})

      return Object.values(rowCells).some(
        (c) => c.hasOwnProperty('footer_cell') && c.footer_cell,
      )
    },
    /* --------------------------------- options parameter --------------------------------- */
    // data,
    // columns,
    // name = '',
    // style = {},
    // cells = {},
    // footers = [],
    // readOnly = false,
    jExcelOptions(options) {
      return {
        data: [
          ...this.parseInputData(options.data),
          ...(!this.dataHasFooter(this.parseInputCells(options.data))
            ? this.parseInputData(options.footers)
            : []),
        ],
        columns: options.readOnly
          ? this.readOnlyColumns(options.columns)
          : this.prixTvaAndTTCColumns(options.columns),
        worksheetName: options.name,
        cells: {
          ...this.parseInputCells([
            ...options.data,
            ...(!this.dataHasFooter(this.parseInputCells(options.data))
              ? options.footers.map((row) => {
                  return row.map((cell) => {
                    return {
                      ...cell,
                      cell_options: {
                        ...cell.cell_options,
                        footer_cell: true,
                      },
                    }
                  })
                })
              : []),
          ]),
        },
        debugFormulas: true,
        allowInsertColumn: false,
        allowManualInsertColumn: false,
        allowDeleteColumn: false,
        allowRenameColumn: false,
        columnSorting: false,
        columnDrag: false,
        onload: (spreadsheet) => {
          const ws = spreadsheet.worksheets[0]
          const footerCells = Object.entries(ws.getCells())
            .filter(
              (item) =>
                item[1].hasOwnProperty('footer_cell') && item[1].footer_cell,
            )
            .map((item) => item[0])

          footerCells.forEach((cell) => {
            ws.getCell(cell).classList.add('footer')
          })

          spreadsheet.worksheets.forEach((worksheet) => {
            const name = worksheet.getConfig().worksheetName
            if (!name.includes('Tableau_Recap')) {
              worksheet.getData().forEach((row, index) => {
                if (typeof row[1] == 'number') {
                  const record = {
                    x: 1,
                    y: index,
                    value: row[1],
                  }
                  this.addPercentage(worksheet, record, name)
                }
              })
            }
          })
        },
        onbeforechange: (worksheet, cell, x, y, value) => {
          if (value == '') cell.innerText = value
        },
        onafterchanges: (worksheet, records) => {
          records.forEach((record) => {
            const data = worksheet.getData()
            const footerCell = {
              x: record.x,
              y: data.length - 1,
              value: data[data.length - 1][record.x],
            }
            worksheet.setValueFromCoords(
              footerCell.x,
              footerCell.y,
              footerCell.value,
              true,
            )
          })
          this.$emit('dataChange', {
            index: this.topicIndex,
            data: this.getParsedData(worksheet),
          })
          this.refreshSummary()
        },
        oneditionend: (worksheet, cell, x, y, newValue, save) => {
          const name = worksheet.getConfig().worksheetName
          if (save) {
            if (!name.includes('Tableau_Recap'))
              this.setByUserCell(worksheet, x)
            let record = {
              value: newValue,
              x: x,
              y: y,
            }
            this.autoCalculations(worksheet, record)
          }
        },
        onformulachain: (worksheet, expressions) => {
          expressions.forEach((expression) => {
            const record = {
              x: expression.x,
              y: expression.y,
              value: expression.f,
            }
            this.autoCalculations(expression.w, record)
          })
        },
        onbeforeinsertrow: (worksheet, rowNumber, numOfRows, insertBefore) => {
          return insertBefore || !this.isFooterSelected(worksheet, rowNumber)
        },
        oninsertrow: (
          worksheet,
          rowNumber,
          numOfRows,
          records,
          insertBefore,
        ) => {
          const y = insertBefore ? rowNumber : rowNumber + 1
          worksheet.setValueFromCoords(0, y, '&nbsp;', true)
        },
        contextMenu: (o, x, y, e, items, section) => {
          // Reset all items
          const name = o.getConfig().worksheetName
          items = []
          if (!options.readOnly) {
            items[0] = {
              title: 'Copier',
              icon: 'content_copy',
              onclick: function () {
                o.copy()
              },
            }

            if (name == 'Tableau_Recap1') {
              items.push({
                title: "Changer l'unité",
                icon: 'swap_horiz',
                submenu: [
                  {
                    title: 'Surface m²',
                    onclick: () => {
                      if (section == 'footer') {
                        this.setFooterCellUnit(o, x, y, 'customSquareMeter')
                      } else if (section != 'header') {
                        const cells = o.getSelected(true, false)
                        cells.forEach((cell) => {
                          this.setCellUnit(o, cell, 'customSquareMeter')
                        })
                      }
                    },
                  },
                  {
                    title: 'Euro €',
                    onclick: () => {
                      if (section == 'footer') {
                        this.setFooterCellUnit(o, x, y, 'customFrEuro')
                      } else if (section != 'header') {
                        const cells = o.getSelected(true, false)
                        cells.forEach((cell) => {
                          this.setCellUnit(o, cell, 'customFrEuro')
                        })
                      }
                    },
                  },
                  {
                    title: 'Pourcentage %',
                    onclick: () => {
                      if (section == 'footer') {
                        this.setFooterCellUnit(o, x, y, 'customPercent')
                      } else if (section != 'header') {
                        const cells = o.getSelected(true, false)
                        cells.forEach((cell) => {
                          this.setCellUnit(o, cell, 'customPercent')
                        })
                      }
                    },
                  },
                  {
                    title: 'Nombre',
                    onclick: () => {
                      if (section == 'footer') {
                        this.setFooterCellUnit(o, x, y, 'numeric')
                      } else if (section != 'header') {
                        const cells = o.getSelected(true, false)
                        cells.forEach((cell) => {
                          this.setCellUnit(o, cell, 'numeric')
                        })
                      }
                    },
                  },
                ],
              })
            }

            items.push({
              title: 'Insérer une nouvelle ligne avant',
              icon: 'playlist_add',
              onclick: function () {
                o.insertRow(1, parseInt(y), true, ['&nbsp;'])
              },
            })

            if (!this.isFooterSelected(o, y)) {
              items.push({
                title: 'Insérer une nouvelle ligne après',
                icon: 'playlist_add',
                onclick: function () {
                  o.insertRow(1, parseInt(y), false, ['&nbsp;'])
                },
              })
            }

            if (!this.getRowIsLocked(o, y) && !this.isFooterSelected(o, y)) {
              items.push({
                title: 'Supprimer la ligne',
                icon: 'playlist_remove',
                onclick: () => {
                  this.removePercentage(o, y)
                  o.deleteRow(parseInt(y), 1)
                },
              })
            }

            if (x < 4) {
              items.push({
                title: 'Vérrouiller la selection',
                icon: 'lock',
                onclick: () => {
                  const cells = o.getSelected(true, false)
                  cells.forEach((cell) => {
                    this.lockSelfCell(o, cell)
                  })
                },
              })

              items.push({
                title: 'devérrouiller la selection',
                icon: 'lock_open',
                onclick: () => {
                  const cells = o.getSelected(true, false)
                  cells.forEach((cell) => {
                    this.unLockCell(o, cell)
                  })
                },
              })

              if (!name.includes('Tableau_Recap')) {
                items.push({
                  title: "Sélectionner comme céllules d'acquisition",
                  icon: 'real_estate_agent',
                  onclick: () => {
                    const cells = o.getSelected(true, false)
                    cells.forEach((cell) => {
                      this.setAcquisitionCell(o, cell)
                    })
                  },
                })

                items.push({
                  title: "Desélectionner comme céllules d'acquisition",
                  icon: 'real_estate_agent',
                  onclick: () => {
                    const cells = o.getSelected(true, false)
                    cells.forEach((cell) => {
                      this.unsetAcquisitionCell(o, cell)
                    })
                  },
                })
              }
            }
          }
          return items
        },
      }
    },
  },
}
